import React, {useState, useEffect} from 'react'
import { Box, Button, Card, CardActions, CardContent, Chip, Container, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, NativeSelect, Paper, Select, Snackbar, TextField, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';
import { sizing } from '@material-ui/system';
import ClockAsFunction from './ClockAsFunction';
import Auth from '../utils/Auth';
import { Redirect } from 'react-router';
import MessageComponent from './MessageComponent';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  grow: {
    flexGrow: 1,
  },
  boxContentLeft: {
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  boxContentRight: {
    display: 'flex',
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  icoPrinted: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  buttonBar: {
    padding: theme.spacing(0),
  },
  headerLabel: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      // width: theme.spacing(140),
      width: "100%",
      height: theme.spacing(16),
    },
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 205,
  },
  formSelectControl: {
    minWidth: 80,
    textAlign: 'left'
  },
}));

// Verwenden

function Einstellungen(props) {
  const classes = useStyles();
  const [plustime, setPlustime] = useState('0');
  const [onlineNachricht, setOnlineNachricht] = useState('');
  const [offlineNachricht, setOfflineNachricht] = useState('');
  const [shopActive, setShopActive] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [anzeigeOeffnungszeiten, setAnzeigeOeffnungszeiten] = useState("");
  const [abholzeitenNormal, setAbholzeitenNormal] = useState("");
  const [abholzeitenFeiertage, setAbholzeitenFeiertage] = useState("");
  const [preorderDays, setPreorderDays] = useState('');
  const [printMinBeforePickup, setPrintMinBeforePickup] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [logMessage, setLogMessage] = useState();


  useEffect(() => {
    props.setSitename("Einstellungen");
    
    //--------------------------------------------------------------------------
    // SHOP-ONLINE
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_online').then(
      res => {
        console.log("kia_order_online res", res);
        
        if(res.data == 'on') {
          setShopActive(true);
        } else {
          setShopActive(false);
        }
      }
    ).catch(
      err => {
        console.log(`kia_order_online err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // PLUSTIME
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_plustime').then(
      res => {
        console.log("kia_order_plustime res", res);
        setPlustime(res.data);
      }
    ).catch(
      err => {
        console.log(`kia_order_plustime err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // PREORDER-DAYS (BESTELLTAGE IM VORAUS)
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_bestelltage_im_voraus').then(
      res => {
        console.log("kia_order_bestelltage_im_voraus res", res);
        setPreorderDays(res.data);
      }
    ).catch(
      err => {
        console.log(`kia_order_bestelltage_im_voraus err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // PRINT-BEFORE-PICKUP (DRUCKEN VOR ABHOLUNG)
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_printbeforepickup').then(
      res => {
        console.log("kia_order_printbeforepickup res", res);
        setPrintMinBeforePickup(res.data);
      }
    ).catch(
      err => {
        console.log(`kia_order_printbeforepickup err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // ANZEIGE ÖFFNUNGSZEITEN
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_oeffnungszeiten').then(
      res => {
        console.log("kia_order_oeffnungszeiten res", res);
        setAnzeigeOeffnungszeiten(res.data);
      }
    ).catch(
      err => {
        console.log(`kia_order_oeffnungszeiten err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // NACHRICHT ANZEIGEN JA/NEIN
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_showNotification').then(
      res => {
        console.log("kia_order_showNotification res", res);
        
        if(res.data == 'on') {
          setShowMessage(true);
        } else {
          setShowMessage(false);
        }
      }
    ).catch(
      err => {
        console.log(`kia_order_showNotification err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // NACHRICHT (BEI ONLINE)
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_notification_online').then(
      res => {
        console.log("kia_order_notification_online res", res);
        setOnlineNachricht(res.data);
      }
    ).catch(
      err => {
        console.log(`kia_order_notification_online err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // NACHRICHT (BEI OFFLINE)
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_notification_offline').then(
      res => {
        console.log("kia_order_notification_offline res", res);
        setOfflineNachricht(res.data);
      }
    ).catch(
      err => {
        console.log(`kia_order_notification_offline err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // ABHOLZEITEN NORMAL (MO-SO)
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_abholzeiten_normal').then(
      res => {
        console.log("kia_order_abholzeiten_normal res", res);
        setAbholzeitenNormal(res.data);
      }
    ).catch(
      err => {
        console.log(`kia_order_abholzeiten_normal err`, err);
      }
    )
    //--------------------------------------------------------------------------
    // ABHOLZEITEN FEIERTAGE
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_abholzeiten_feiertage').then(
      res => {
        console.log("kia_order_abholzeiten_feiertage res", res);
        setAbholzeitenFeiertage(res.data);
      }
    ).catch(
      err => {
        console.log(`kia_order_abholzeiten_feiertage err`, err);
      }
    )
    //--------------------------------------------------------------------------

    return () => {
      //cleanup
    }
  }, [])

  const handleShowMessage = (e) => {
    // e.preventDefault();
    //setShowMessage(e.target.checked);
    setShowMessage((prev) => !prev);

    let data;
    if(e.target.checked == true) {
      data = {setting_value: 'on'}
    } else {
      data = {setting_value: 'off'}
    }

    console.log(`settings_value`, data.settings_value);

    Auth.axios.put(`wp-json/kiarest/v1/settings/kia_order_showNotification`, data).then(
      res => {
        console.log("Result handleShowMessage", res);
        console.log("Result handleShowMessage e.target.checked", e.target.checked);
        //setShowMessage(e.target.checked);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log("err", err);
        logError("Fehler.");
      }
    )
  }

  const handleShopActive = (e) => {
    // e.preventDefault();
    //setShopActive(e.target.checked);
    setShopActive((prev) => !prev);

    let data;
    if(e.target.checked == true) {
      data = {setting_value: 'on'}
    } else {
      data = {setting_value: 'off'}
    }

    console.log(`settings_value`, data.settings_value);

    Auth.axios.put(`wp-json/kiarest/v1/settings/kia_order_online`, data).then(
      res => {
        console.log("Result handleShopActive", res);
        console.log("Result handleShopActive e.target.checked", e.target.checked);
        //setShopActive(e.target.checked);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log("err", err);
        logError("Fehler.");
      }
    )

  }

  const handleChangePlustime = (e) => {
    e.preventDefault();
    setPlustime(e.target.value);
  }

  const handleChangeAnzeigeOeffnungszeiten = (e) => {
    e.preventDefault();
    console.log(`e.target.value`, e.target.value);
    setAnzeigeOeffnungszeiten(e.target.value);
  }

  const handleChangeOnlineNachricht = (e) => {
    e.preventDefault();
    setOnlineNachricht(e.target.value);
  }

  const handleChangeOfflineNachricht = (e) => {
    e.preventDefault();
    setOfflineNachricht(e.target.value);
  }

  const handleChangeAbholzeitenNormal = (e) => {
    e.preventDefault();
    setAbholzeitenNormal(e.target.value);
  }

  const handleChangeAbholzeitenFeiertage = (e) => {
    e.preventDefault();
    setAbholzeitenFeiertage(e.target.value);
  }

  const handleSaveOnlineshop = (e) => {
    e.preventDefault();
    const data_plustime = {'setting_value': plustime}

    Auth.axios.put(`wp-json/kiarest/v1/settings/kia_order_plustime`, data_plustime).then(
      res => {
        console.log("PUT kia_order_plustime res", res);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log("PUT kia_order_plustime err", err);
        logError("Fehler.");
      }
    )

    const data_preorderdays = {'setting_value': preorderDays}
    Auth.axios.put('wp-json/kiarest/v1/settings/kia_order_bestelltage_im_voraus', data_preorderdays).then(
      res => {
        console.log("PUT kia_order_bestelltage_im_voraus res", res);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log(`PUT kia_order_bestelltage_im_voraus err`, err);
        logError("Fehler.");
      }
    )

    const data_printbeforepickup = {'setting_value': printMinBeforePickup}
    Auth.axios.put('wp-json/kiarest/v1/settings/kia_order_printbeforepickup', data_printbeforepickup).then(
      res => {
        console.log("PUT kia_order_printbeforepickup res", res);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log(`PUT kia_order_printbeforepickup err`, err);
        logError("Fehler.");
      }
    )

    
  }

  const handleSaveOpeninghours = (e) => {
    const data_oeffnungszeiten = {'setting_value': anzeigeOeffnungszeiten};

    Auth.axios.put('wp-json/kiarest/v1/settings/kia_order_oeffnungszeiten', data_oeffnungszeiten).then(
      res => {
        console.log("PUT kia_order_oeffnungszeiten res", res);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log(`PUT kia_order_oeffnungszeiten err`, err);
        logError("Fehler.");
      }
    )
  }
  
  const handleSaveOnlineNachricht = (e) => {
    e.preventDefault();
    const data = {'setting_value': onlineNachricht}

    Auth.axios.put(`wp-json/kiarest/v1/settings/kia_order_notification_online`, data).then(
      res => {
        console.log("Result handleSaveOnlineNachricht", res);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log("err", err);
        logError("Fehler.");
      }
    )
  }

  const handleSaveOfflineNachricht = (e) => {
    e.preventDefault();
    const data = {'setting_value': offlineNachricht}

    Auth.axios.put(`wp-json/kiarest/v1/settings/kia_order_notification_offline`, data).then(
      res => {
        console.log("Result handleSaveOfflineNachricht", res);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log("err", err);
        logError("Fehler.");
      }
    )
  }

  const handleSaveAbholzeitenNormal = (e) => {
    e.preventDefault();
    const data = {'setting_value': abholzeitenNormal}

    Auth.axios.put(`wp-json/kiarest/v1/settings/kia_order_abholzeiten_normal`, data).then(
      res => {
        console.log("Result handleSaveAbholzeitenNormal", res);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log("err", err);
        logError("Fehler.");
      }
    )
  }

  const handleSaveAbholzeitenFeiertage = (e) => {
    e.preventDefault();
    const data = {'setting_value': abholzeitenFeiertage}

    Auth.axios.put(`wp-json/kiarest/v1/settings/kia_order_abholzeiten_feiertage`, data).then(
      res => {
        console.log("Result handleSaveAbholzeitenFeiertage", res);
        logSuccess("Erfolgreich gespeichert.");
      }
    ).catch(
      err => {
        console.log("err", err);
        logError("Fehler.");
      }
    )
  }

  const handleVorlageAbholzeitenNormal = (e) => {
    e.preventDefault();
    const vorlage = `{
      "Monday":
      ["17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00"],
      
      "Tuesday":
      ["11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00"],
      
      "Wednesday":
      ["11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00"],
      
      "Thursday":
      ["11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00"],
      
      "Friday":
      ["11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45"],
      
      "Saturday":
      ["11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45"],
      
      "Sunday": []
}`;

    setAbholzeitenNormal(vorlage);
  }

  const handleVorlageAbholzeitenFeiertage = (e) => {
    //e.preventDefault();
    const vorlage = `[{
      "Abholdatum":  "14.02.2021",
      "Info":        "Valentinstag",
      "Abholzeiten":
      ["17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00"]
}]`;

    setAbholzeitenFeiertage(vorlage);
  }

  const handleChangePreorderDays = (e) => {
    //e.preventDefault();
    
    if(e.target.value.length > 3) {
      return;
    }

    if(isNaN(e.target.value)){
      return;
    }

    setPreorderDays(e.target.value);
  }

  const handleChangePrintMinBeforePickup = (e) => {
    //e.preventDefault();
    
    if(e.target.value.length > 4) {
      return;
    }

    if(isNaN(e.target.value)){
      return;
    }

    setPrintMinBeforePickup(e.target.value);
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  }

  const logSuccess = (content) => {
    setLogMessage({key: `${new Date()}`, msg: `${content}`, severity: 'success'});
  }

  const logError = (content) => {
    setLogMessage({key: `${new Date()}`, msg: `${content}`, severity: 'error'});
  }

  if(Auth.currentUser == null) {
    return <Redirect to={"/login"} />
  } else {
  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          {/********************************************************************************
            * 50% Onlineshop AKTIV & MINDEST ABHOLZEIT
            ********************************************************************************/}
          <Grid item xs={12} sm={12} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" align="left">Onlineshop</Typography>
                {/* <Typography className={classes.title} color="textSecondary" align="left" gutterBottom>Online Bestellung Einstellungen.</Typography> */}
                <Box mt={1}>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs mt={1}>
                      <Paper elevation={1} style={{'paddingTop': '8px','paddingBottom': '8px', 'margin': '0 auto', 'width': '205px'}}>
                        <FormControlLabel
                            control={<Switch checked={shopActive} onChange={handleShopActive} color="primary" name="ShopActive" inputProps={{ 'aria-label': 'primary checkbox'}} />}
                            label="Aktiv"
                          />
                      </Paper>
                    </Grid>
                    <Grid item xs>
                      <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Mindest Abholzeit</InputLabel>
                        <Select
                          labelId="mindest-abholzeit-select-outlined-label"
                          id="mindest-abholzeit-simple-select-outlined"
                          value={plustime}
                          onChange={handleChangePlustime}
                          label="Mindest Abholzeit"
                          className={classes.formSelectControl}
                        >
                          {/* <MenuItem value=""><em>None</em></MenuItem> */}
                          <MenuItem value={15}>0:15</MenuItem>
                          <MenuItem value={30}>0:30</MenuItem>
                          <MenuItem value={45}>0:45</MenuItem>
                          <MenuItem value={60}>1:00</MenuItem>
                          <MenuItem value={75}>1:15</MenuItem>
                          <MenuItem value={90}>1:30</MenuItem>
                          <MenuItem value={105}>1:45</MenuItem>
                          <MenuItem value={120}>2:00</MenuItem>
                        </Select>
                      </FormControl>
                      {/* <FormControlLabel className={classes.formControl}
                        control={
                          <NativeSelect
                          value={plustime}
                          onChange={handleChangePlustime}
                          className={classes.formSelectControl}
                          inputProps={{name: 'plustime', id: 'plustime-native-helper',}}
                        >
                          <option aria-label="None" value="" />
                          <option value={15}>0:15</option>
                          <option value={30}>0:30</option>
                          <option value={45}>0:45</option>
                          <option value={60}>1:00</option>
                          <option value={75}>1:15</option>
                          <option value={90}>1:30</option>
                          <option value={105}>1:45</option>
                          <option value={120}>2:00</option>
                        </NativeSelect>}
                        label="&nbsp;Mindest Abholzeit"
                      /> */}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="center">
                    <Grid item xs>
                      <TextField id="filled-multiline-static" label="Bestelltage im Voraus" value={preorderDays} variant="outlined" onChange={handleChangePreorderDays} className={classes.formControl} InputLabelProps={{ shrink: true }} />
                    </Grid>
                    <Grid item xs>
                      <TextField id="filled-multiline-static" label="Drucken vor Abholung (Min)" value={printMinBeforePickup} variant="outlined" onChange={handleChangePrintMinBeforePickup} className={classes.formControl} InputLabelProps={{ shrink: true }} />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={2} className={classes.boxContentRight}>
                  <Button variant="contained" color="primary" onClick={handleSaveOnlineshop}>Speichern</Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/********************************************************************************
            * 50% ANZEIGE ÖFFNUNGSZEITEN
            ********************************************************************************/}
          <Grid item xs={12} sm={12} lg={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" align="left">Anzeige Öffnungszeiten</Typography>
                <Typography className={classes.title} color="textSecondary" align="left" gutterBottom>Anzeige der Öffnungszeiten auf der Homepage an vielen Stellen. Hat keine Auswirkungen auf das Online Bestellsystem.</Typography>
                <TextField id="filled-multiline-static" multiline rows={4} label="Anzeige Öffnungszeiten" value={anzeigeOeffnungszeiten} variant="outlined" fullWidth onChange={handleChangeAnzeigeOeffnungszeiten} InputLabelProps={{ shrink: true }} />
                <Box mt={2} className={classes.boxContentRight}>
                  <Button variant="contained" color="primary" onClick={handleSaveOpeninghours}>Speichern</Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          {/********************************************************************************
            * 100% NACHRICHTEN ONLINE & OFFLINE
            ********************************************************************************/}
          <Grid item xs={12}>
            <Box mt={4}>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box mb={2}><Typography variant="h5" component="h2" align="left">Nachricht im Shop</Typography></Box>
                      <Box mt={2} className={classes.boxContentLeft}>
                        <FormControlLabel
                          control={<Switch checked={showMessage} onChange={handleShowMessage} color="primary" name="ShopShowMessage" inputProps={{ 'aria-label': 'primary checkbox'}} />}
                          label="Nachricht anzeigen"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>                 
                      <TextField id="filled-multiline-static" label="Online Nachricht" multiline rows={8} value={onlineNachricht} variant="outlined" fullWidth onChange={handleChangeOnlineNachricht} InputLabelProps={{ shrink: true }} />
                      <Typography className={classes.title} color="textSecondary" align="right" gutterBottom>Wird angezeigt, wenn das Bestellsystem online ist.</Typography>
                      <Box mt={2} className={classes.boxContentRight}>
                        <Button variant="contained" color="primary" onClick={handleSaveOnlineNachricht}>Speichern</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{margin: 'auto 0'}}>
                      <TextField id="filled-multiline-static" label="Offline Nachricht" multiline rows={8} value={offlineNachricht} variant="outlined" fullWidth onChange={handleChangeOfflineNachricht} InputLabelProps={{ shrink: true }} />
                      <Typography className={classes.title} color="textSecondary" align="right" gutterBottom>Wird angezeigt, wenn das Bestellsystem offline ist.</Typography>
                      <Box mt={2} className={classes.boxContentRight}>
                        <Button variant="contained" color="primary" onClick={handleSaveOfflineNachricht}>Speichern</Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          {/********************************************************************************
            * 100% ABHOLZEITEN MO-SO
            ********************************************************************************/}
          <Grid item xs={12}>
            <Box mt={4}>
              <Card>
                <CardContent>
                  <Box mb={2}>
                    <Typography variant="h5" component="h2" align="left">Shop Abholzeiten Mo-So</Typography>
                  </Box>
                  <Grid item xs={12}>
                    <Box component="div" whiteSpace="normal">
                      <TextField className={classes.multilineTextboxNoWrap} id="filled-multiline-static" label="Abholzeiten Mo-So" multiline rows={12} value={abholzeitenNormal} variant="outlined" fullWidth onChange={handleChangeAbholzeitenNormal} InputLabelProps={{ shrink: true }} />
                    </Box>
                    <Typography className={classes.title} color="textSecondary" align="right" gutterBottom>Generell übliche Öffnungszeiten, Montag bis Sonntag.</Typography>
                    <Box mt={2} className={classes.boxContentRight}>
                      <Button variant="contained" onClick={handleVorlageAbholzeitenNormal} style={{'marginRight': '20px'}}>Vorlage laden</Button>
                      <Button variant="contained" color="primary" onClick={handleSaveAbholzeitenNormal}>Speichern</Button>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
          {/********************************************************************************
            * 100% ABHOLZEITEN FEIERTAGE
            ********************************************************************************/}
          <Grid item xs={12}>
            <Box mt={4}>
              <Card>
                <CardContent>
                  <Box mb={2}>
                    <Typography variant="h5" component="h2" align="left">Shop Abholzeiten Feiertage</Typography>
                  </Box>
                  <Grid item xs={12}>                 
                    <TextField id="filled-multiline-static" label="Abholzeiten Feiertage" multiline rows={10} value={abholzeitenFeiertage} variant="outlined" fullWidth onChange={handleChangeAbholzeitenFeiertage} InputLabelProps={{ shrink: true }} />
                    <Typography className={classes.title} color="textSecondary" align="right" gutterBottom>Abholzeiten an bestimmten Feiertagen.</Typography>
                    <Box mt={2} className={classes.boxContentRight}>
                      <Button variant="contained" onClick={(e) => {setAbholzeitenFeiertage("{}")}} style={{'marginRight': '20px'}}>Keine Feiertage</Button>
                      <Button variant="contained" onClick={handleVorlageAbholzeitenFeiertage} style={{'marginRight': '20px'}}>Vorlage laden</Button>
                      <Button variant="contained" color="primary" onClick={handleSaveAbholzeitenFeiertage}>Speichern</Button>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <Snackbar open={openSnackbar} autoHideDuration={4000} onClose={handleCloseSnackbar} anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} >
        <Alert severity="success">
          Erfolgreich gespeichert
        </Alert>
      </Snackbar> */}
      <MessageComponent message={logMessage} />
    </React.Fragment>
    // <Container className={classes.container} maxWidth="lg">
    //   <Grid className={classes.buttonBar} container  direction="row" justify="flex-start" alignItems="center">
    //     <Card className={classes.root}>
    //       <CardContent>
    //         <Typography className={classes.title} color="textSecondary" gutterBottom>
    //           Word of the Day
    //         </Typography>
    //         <Typography variant="h5" component="h2">
    //           be{bull}nev{bull}o{bull}lent
    //         </Typography>
    //         <Typography className={classes.pos} color="textSecondary">
    //           adjective
    //         </Typography>
    //         <Typography variant="body2" component="p">
    //           well meaning and kindly.
    //           <br />
    //           {'"a benevolent smile"'}
    //         </Typography>
    //       </CardContent>
    //       <CardActions>
    //         <Button size="small">Learn More</Button>
    //       </CardActions>
    //     </Card>
    //   </Grid>
    // </Container>
  )}
}

export default Einstellungen


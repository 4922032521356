import React, {useEffect} from 'react'
import { Redirect } from 'react-router'
import { Container, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';
import Auth from '../utils/Auth';
import BestellungenTopBar from './BestellungenTopBar';
import OrderTableAdvanced from './OrderTableAdvanced';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  icoPrinted: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  buttonBar: {
    padding: theme.spacing(3),
  }
}));

export default function Bestellungen(props) {
  const classes = useStyles();

  useEffect(() => {
    props.setSitename("Bestellungen");
  },[]);

  if(Auth.currentUser == null) {
    return <Redirect to={"/login"} />
  } else {
    return (
      <React.Fragment>
        <OrderTableAdvanced />
      </React.Fragment>
    )
  }
}

import React, { useState, useEffect } from 'react'
import { Button, Snackbar } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab';

const AUTO_DISMISS = 3000

const MessageComponent = ({ message }) => {
  console.log("MessageComponent message: ", message );
  // console.log("MessageComponent messages.length > 0: ", messages.length > 0);
  const [open, setOpen] = useState(true);
  

  useEffect(() => {
    console.log(`MessageComponent useEffect aufgerufen`);
    if (message != undefined) { 
      setOpen(true);
    }
  }, [message]);

  const onClose = (event, reason) => {
    console.log("MessageComponent calling onClose()...");
    if(reason === 'clickaway') {
      return;
    }
    
    setOpen(false);
  }

  if (message == undefined) {
    return <></>
  }

  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={AUTO_DISMISS} onClose={onClose} anchorOrigin={{vertical: 'bottom', horizontal: 'center',}} >
        <Alert severity={message.severity}>
          {message.msg}
        </Alert>
      </Snackbar>
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={`top,center`}
        open={showMessage}
        autoHideDuration={AUTO_DISMISS}

        // eventurally I want to display all messages but this will work for now.
        message={messages[0]}
        onClose={onClose}
      />
      Proof that Snackbar should be rendered here: {messages[0]} */}
    </React.Fragment>
  )
}

export default MessageComponent;
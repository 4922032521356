import React, {useState, useEffect} from 'react';
import clsx from 'clsx';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green, orange, blue, lightBlue } from '@material-ui/core/colors';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import ChatIcon from '@material-ui/icons/Chat';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Nachrichten from './Nachrichten';
import Login from './Login'
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { Router } from 'react-router'
import { useHistory } from 'react-router-dom';
import Einstellungen from './Einstellungen';
import Bestellungen from './Bestellungen';
import axios from 'axios';
import Auth from '../utils/Auth';

const drawerWidth = 240;

const myTheme = createMuiTheme({
  palette: {
    primary: {
      main: lightBlue[800],
      // main: "#00909B", // Sushipark grün
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    //flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    //flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [sitename, setSitename] = useState("");
  let history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();
    console.log("handleLogout");
    Auth.logout();
    setOpen(false);
    history.push("/login");
  }

  /*useEffect(() => {
    axios.post('wp-json/wp/v2/users/me').then(
      res => {
        console.log("Home.js RESULT: ", res);
        console.log("Home.js USER DATA: ", res.data);
        setUser(res.data);
      }
    ).catch(
      err => { console.log("Home.js: ", err); }
    )

    return () => {
      //cleanup
    }
  }, []);*/

  return (
    <ThemeProvider theme={myTheme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, { [classes.appBarShift]: open, })}
        >
          <Toolbar>
            {Auth.currentUser &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            }
            <Typography variant="h6" noWrap className={classes.title}>
              {sitename}
            </Typography>
            <div className={classes.grow} />
            { Auth.currentUser == null ? <Button color="inherit" component={Link} to={"/login"} >Login</Button> : <Button color="inherit" onClick={handleLogout} >Logout</Button> }
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button key="bestellungen" component={Link} to={'/bestellungen'} >
              <ListItemIcon><ShoppingCartIcon /></ListItemIcon>
              <ListItemText primary="Bestellungen" />
            </ListItem>
            <ListItem button key="nachrichten" component={Link} to={'/nachrichten'} >
              <ListItemIcon><ChatIcon /></ListItemIcon>
              <ListItemText primary="Nachrichten" />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button key="einstellungen" component={Link} to={'/einstellungen'}>
              <ListItemIcon><SettingsApplicationsIcon /></ListItemIcon>
              <ListItemText primary="Einstellungen" />
            </ListItem>
          </List>
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          <Switch>
            <Route exact path={["/","/bestellungen"]} component={() => <Bestellungen setSitename={setSitename} />} />
            <Route exact path="/nachrichten" component={() => <Nachrichten setSitename={setSitename} />} />
            <Route exact path="/einstellungen" component={() => <Einstellungen setSitename={setSitename} />} />
            <Route exact path="/login" component={() => <Login setSitename={setSitename} />} />
          </Switch>
          
        </main>
      </div>
    </ThemeProvider>
  );
}

export default Home;
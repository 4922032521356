import React, {Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconPrinted from '@material-ui/icons/Check';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import { green, grey } from '@material-ui/core/colors';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  icoPrinted: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[500],
    },
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function OrderTable({data}) {
  const classes = useStyles();
  console.log("from OrderTable: ", data);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Telefon</TableCell>
            <TableCell align="right">Summe</TableCell>
            <TableCell align="right">Eingang</TableCell>
            <TableCell align="right">Abholung</TableCell>
            <TableCell align="right">Gedruckt</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">{row.order_bestellnummer}</TableCell>
              <TableCell align="right">{row.order_vorname} {row.order_nachname}</TableCell>
              <TableCell align="right">{row.order_telefon}</TableCell>
              <TableCell align="right">{row.order_gesamtsumme}</TableCell>
              <TableCell align="right">{row.bestellzeit}</TableCell>
              <TableCell align="right">{row.order_abholzeit}</TableCell>
              <TableCell align="right">{row.order_gedruckt == 'on' ? <Icon style={{ color: green[500] }}>check_circle</Icon> : <Icon style={{ color: grey[500] }}>circle</Icon>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
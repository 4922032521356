import logo from './logo.svg';
import './App.css';
import OrderView from './components/OrderView'
import Home from './components/Home'
import { Router, BrowserRouter } from 'react-router-dom';

function App() {
  return(
    <div className="App">
      {/* <OrderView /> */}
      <BrowserRouter basename="/">
        <Home />
      </BrowserRouter>
    </div>
  );
}

export default App;

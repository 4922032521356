import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import Auth from '../utils/Auth';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  loginCard: {
    padding: theme.spacing(5),

  },
  loginHeading: {
    paddingBottom: theme.spacing(2),
  }
}));


function Login(props) {
  const classes = useStyles();
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [message, setMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(null);
  let history = useHistory();

  useEffect(() => {
    props.setSitename("Login");
  },[]);

  const handleSubmit = (e) => {
    e.preventDefault();
  
    console.log(`username`, username);
    console.log(`password`, password);

    // let res = getToken(username, password);
    // console.log(`result getToken: `, res);

    Auth.login(username, password).then(
      res => {
        console.log(`RESULT status nach login:`, Auth.isLoggedIn);
        console.log(`Auth.isLoggedIn: `, Auth.isLoggedIn);
        if(Auth.isLoggedIn){
          Auth.requestCurrentUser().then(
            res => {
              console.log(`res: `, res);
              history.push("/bestellungen");
            }
          ).catch(
            err => {
              console.log(`error: `, err);
            }
          );
          
        }
      }
    ).catch(
      err => {
        console.log(`ERROR status nach login:`, err);
      }
    );

    



    // Auth.post('wp-json/jwt-auth/v1/token', {username, password}).then(
    //   res => {
    //     console.log("Login.js /token result: ", res.data.token);
    //     localStorage.setItem('token', res.data.token);
    //     setLoggedIn(true);

    //     // --------------------------------------
    //     // Aktuellen User abfragen
    //     // --------------------------------------
    //     Auth.post('wp-json/wp/v2/users/me').then(
    //       res => {
    //         console.log("Login.js res: ", res);
    //         console.log("Login.js res.data: ", res.data);
    //         props.setUser(res.data);
    //       }
    //     ).catch(
    //       err => { console.log("/users/me",err); }
    //     )
    //     // --------------------------------------

    //   }
    // ).catch(
    //   err => {
    //     console.log("Login.js /token catch: ", err);
    //     setMessage("Falsches Passwort.");
    //     setLoggedIn(false);
    //     localStorage.clear();
    //     props.setUser(null);
    //   }
    // )



    // console.log(`axios result getting token:`, result);

    // Auth.post('wp-json/wp/v2/users/me').then(
    //   res => {
    //     console.log("Login.js res: ", res);
    //     console.log("Login.js res.data: ", res.data);
    //     props.setUser(res.data);
    //   }
    // ).catch(
    //   err => { console.log("/users/me",err); }
    // )

    // axios.post('wp-json/jwt-auth/v1/token', {username, password}, {headers: {} }).then(
    // Auth.post('wp-json/jwt-auth/v1/token', {username, password}).then(
    //   res => {
    //     console.log("Login.js /token result: ", res.data.token);
    //     localStorage.setItem('token', res.data.token);
    //     setLoggedIn(true);
    //   }
    // ).then(
    //   Auth.post('wp-json/wp/v2/users/me').then(
    //     res => {
    //       console.log("Login.js res: ", res);
    //       console.log("Login.js res.data: ", res.data);
    //       props.setUser(res.data);
    //     }
    //   ).catch(
    //     err => { console.log("/users/me",err); }
    //   )
    // ).catch(
    //   err => {
    //     console.log("Login.js /token catch: ", err);
    //     setMessage("Falsches Passwort.");
    //     setLoggedIn(false);
    //     localStorage.clear();
    //     props.setUser(null);
    //   }
    // )
  
  }

  // if(Auth.currentUser) {
  //   return <Redirect to={"/bestellungen"} />
  // } else {
    return (
      <Container className={classes.container} maxWidth="xs">
        <form onSubmit={handleSubmit}>
          <Card className={classes.root}>
            <CardContent className={classes.loginCard}>
              <Typography variant="h6" gutterBottom align="center" className={classes.loginHeading}>Bitte einloggen</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField fullWidth label="User" name="username" onChange={e => setUsername(e.target.value)} size="small" variant="outlined" autoComplete="username" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField fullWidth label="Passwort" name="password" onChange={e => setPassword(e.target.value)} size="small" type="password" variant="outlined" autoComplete="current-password" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button color="secondary" fullWidth type="submit" variant="contained">Log in</Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </form>
      </Container>
    )
  // }
}

export default Login;
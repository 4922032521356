import React, {useState, useEffect} from 'react';

function ClockAsFunction() {
  const [ date, setDate ] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => tick(), 1000);

    return () => {
      console.log('clearing interval');
      clearInterval(interval);
    };
  })

  function tick() {
    setDate(new Date());
  }

  if(date == null){
    return <div></div>
  }

  return (
    // <h1>{new Intl.DateTimeFormat("de-DE", {year: "numeric", month: "long", day: "2-digit"}).format(date)} {date.toLocaleTimeString()}</h1>
    <h1>{new Intl.DateTimeFormat("de-DE", {month: "long", day: "2-digit"}).format(date)} {date.toLocaleTimeString()}</h1>
  )
}

export default ClockAsFunction
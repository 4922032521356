import React, {useState, useEffect, useCallback} from 'react'
import Container from '@material-ui/core/Container';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { Redirect } from 'react-router';
import Auth from '../utils/Auth';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, IconButton } from '@material-ui/core';
import NachrichtenNew from './NachrichtenNew';
import NachrichtenEdit from './NachrichtenEdit';
//import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  icoPrinted: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  buttonBar: {
    padding: theme.spacing(3),
  }
}));



export default function Nachrichten(props) {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [update, setUpdate] = useState();
  let isMounted = false;

  useEffect(() => {
    isMounted=true;
    props.setSitename("Nachrichten");

    Auth.axios.get('wp-json/wp/v2/kiapopup?status=draft,publish').then(
      res => {
        console.log(`res`, res);
        let result=[];
        //const d = JSON.parse(res.data);
        res['data'].forEach(obj => {
          result.push({
            'id':       obj.id,
            'date':     obj.date,
            'modified': obj.modified,
            'status':   obj.status,
            'title':    obj.title.rendered,
            'content':  obj.content.rendered
          });
        });

        console.log(`result`, result);

        setData(result);
      }
    ).catch(
      err => {
        console.log(`err`, err);
      }
    )

    return () => {
      isMounted=false;
    }

  },[update]);

  function setPublished(d, id, checkedvalue) {
    for (var i=0 ; i<d.length ; i++) {
      if (d[i].id === id) {
        d[i].published = !checkedvalue;
        // return;
      }
    }
    return d;
  }

  const handleChangePublished = useCallback((event) => {
    event.preventDefault();

    let publishData;
    if(event.target.checked == true) {
      publishData = {status: 'publish'}
    } else {
      publishData = {status: 'draft'}
    }

    Auth.axios.put(`wp-json/wp/v2/kiapopup/${event.target.id}`, publishData).then(
      res => {
        console.log("res", res);
        setUpdate(new Date());
      }
    ).catch(
      err => {
        console.log("err", err);
      }
    )

  },[]);

  const handleDelete = (e) => {
    e.preventDefault();

    Auth.axios.delete(`wp-json/wp/v2/kiapopup/${e.currentTarget.getAttribute("data-id")}`, data).then(
      res => {
        console.log("res", res);
        setUpdate(new Date());
      }
    ).catch(
      err => {
        console.log("err", err);
      }
    )

  };

  if(Auth.currentUser == null) {
    <Redirect to={"/login"} />
  }
  
  if(data == null) {
    return (<div><br /><CircularProgress /><br />loading...</div>)
  }
  else {
    return (
      <Container className={classes.container} maxWidth="lg">
        <Grid className={classes.buttonBar} container  direction="row" justify="flex-start" alignItems="center">
          <NachrichtenNew setUpdate={setUpdate}/>
        </Grid>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Titel</TableCell>
                <TableCell align="left">Nachricht</TableCell>
                <TableCell align="right">ON/OFF</TableCell>
                <TableCell align="right">Bearbeiten</TableCell>
                <TableCell align="right">Löschen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">{row.title}</TableCell>
                  <TableCell align="left"><div dangerouslySetInnerHTML={{ __html: row.content }}></div></TableCell>
                  {/* <TableCell align="left">{row.content}</TableCell> */}
                  <TableCell align="right"><Switch checked={row.status == "publish" ? true : false} onChange={handleChangePublished} color="primary" name={`PopupPublished_${row.id}`} inputProps={{ 'aria-label': 'primary checkbox', id: row.id }} /></TableCell>
                  <TableCell align="right">
                      <NachrichtenEdit data={{id:row.id, title:row.title, content:row.content}} />
                  </TableCell>
                  <TableCell align="right"><IconButton onClick={handleDelete} data-id={row.id} ><Icon style={{ color: red[500] }}>delete</Icon></IconButton></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    )
  }
}
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import DirectionsIcon from '@material-ui/icons/Directions';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
}));

function SearchBarPlus(props) {
  const classes = useStyles();
  const [value, setValue] = useState("");

  const onClickSearch = (e) => {
    e.preventDefault();
  }
  
  const onClickCancel = (e) => {
    e.preventDefault();
    props.onChange("");
    setValue("");
  }

  const onChangeInput = (e) => {
    e.preventDefault();
    props.onChange(e.target.value);
    setValue(e.target.value);
  }

  const onKeyUp = (e) => {
    e.preventDefault();
    // Wenn ESC gedrückt wird: e.key="Escape" und e.keyCode=27
    if(e.keyCode == 27) {
      props.onChange("");
      setValue("");
    }
  }

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Suche"
        value={value}
        onChange={(e) => onChangeInput(e)}
        onKeyUp={(e) => onKeyUp(e)}
        inputProps={{ 'aria-label': 'suche' }}
      />
      {!value &&
        <IconButton type="submit" className={classes.iconButton} onClick={onClickSearch} aria-label="search">
          <SearchIcon />
        </IconButton>
      }
      {value &&
        <IconButton type="submit" className={classes.iconButton} onClick={onClickCancel} aria-label="search">
          <CloseIcon />
        </IconButton>
      }
    </Paper>
  );
}

export default SearchBarPlus;
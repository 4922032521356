import React, {useState, useEffect} from 'react'
import { green, grey, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import config from '../utils/config.js'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    // 'padding-top': '20px'
  },
  headerLabel: {
    fontWeight: 'bold',
    fontSize: '16px',
  }
});

function HeaderStatus({token}) {
  const classes = useStyles()
  const [label, setLabel] = useState({onlineStatus: 'loading', plustime: '0'});
  const [settings, setSettings] = useState({kia_order_online: null, kia_order_plustime: null});

  useEffect(() => {

    console.log('initializing interval');
    const interval = setInterval(() => {
      
      axios.get(config.siteurl + '/wp-json/kiarest/v1/settings/kia_order_online', {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + token }
      })
        .then(res => {
          setSettings({ kia_order_online: res.data });
          console.log("kia_order_online: " + res.data);
  
          if(res.data == 'on') {
            setLabel({ onlineStatus: 'ONLINE BESTELLUNG AKTIV' });
          } else {
            setLabel({ onlineStatus: 'BESTELLUNG OFFLINE' });
          }
        })
        .catch(err => console.log('Fetch Error: ', err))
      
      axios.get(config.siteurl + '/wp-json/kiarest/v1/settings/kia_order_plustime', {
        method: 'GET',
        headers: { 'Authorization': 'Bearer ' + token }
      })
        .then(res => {
          setSettings({ kia_order_plustime: res.data })
        })
        .catch(err => console.log('Fetch Error: ', err))

    }, 2000);

    return () => {
      console.log('clearing interval');
      clearInterval(interval);
    };

  }, []);

  return (
    <React.Fragment>
      <Chip color="primary" className={classes.headerLabel} label={label.onlineStatus} style={{ backgroundColor: green[500] }} />&nbsp;
      <Chip color="primary" className={classes.headerLabel} label={`+ ${settings.kia_order_plustime}min`} style={{ backgroundColor: grey[500] }} />
    </React.Fragment>
  )

}

export default HeaderStatus;
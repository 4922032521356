import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import config from '../utils/config.js'
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import OrderTable from './OrderTable'
import OrderTableAdvanced from './OrderTableAdvanced'
import ClockAsFunction from './ClockAsFunction'
import SettingsForm from './SettingsForm';
import HeaderStatus from './HeaderStatus';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    // 'padding-top': '20px'
  },
  headerLabel: {
    fontWeight: 'bold',
    fontSize: '16px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class OrderView extends Component {
  constructor(){
    super();
    this.clickHandler = this.clickHandler.bind(this);
    this.clickHandlerFetch = this.clickHandlerFetch.bind(this);
    //this.getOrders = this.getOrders.bind(this);

    this.state = {
      data: [],
      settings: {
        kia_order_online: null,
        kia_order_plustime: null
      },
      isLoaded: false,
      token: null
    };
  }

  getToken() {
    fetch(config.siteurl + '/wp-json/jwt-auth/v1/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: "admin",
        password: "maki#nigiri#2020!"})
      })
      .then(res => res.json())
      .then(json => {
        console.log(json)
        this.setState({token: json.token})
      })
  }

  tokenIsValid(callback) {
    fetch(config.siteurl + '/wp-json/jwt-auth/v1/token/validate', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + this.state.token
      }
    })
      .then(res => res.json())
      .then(json => {
        // console.log(json)
        // console.log("JSON.CODE: '" + json.code + "'")
        if(json.code == "jwt_auth_valid_token") {
          // console.log("true")
          callback();
          return true;
        } else {
          // console.log("false")
          this.getToken();
          // return false;
        }
      })
  }

  getOrders(){
          //fetch('https://randomuser.me/api')
      //fetch('https://test.sushipark.de/wp-json/wp/v2/resorder')
      fetch(config.siteurl + '/wp-json/kiarest/v1/resorder/',{
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + this.state.token
        }
      })
        .then(response => response.json())
        .then(json => {
          this.setState({ data: json, isLoaded: true });
          // console.log("Received Resorder: ", json);
        })
        .catch(err => console.log('Fetch Error: ', err))  
  }

  getSettings(){

    // kia_order_online
    fetch(config.siteurl + '/wp-json/kiarest/v1/settings/kia_order_online', {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + this.state.token }
    })
      .then(response => response.json())
      .then(json => { this.setState({ settings: { kia_order_online: json } }); })
      .catch(err => console.log('Fetch Error: ', err))

    // kia_order_plustime
    fetch(config.siteurl + '/wp-json/kiarest/v1/settings/kia_order_plustime', {
      method: 'GET',
      headers: { 'Authorization': 'Bearer ' + this.state.token }
    })
      .then(response => response.json())
      .then(jsonn => { this.setState({ settings: { kia_order_plustime: jsonn } }); })
      .catch(err => console.log('Fetch Error: ', err))  
}

  getAllData() {

    this.tokenIsValid(() => {

      this.getOrders();
      //this.getSettings();

    });
  }

  componentWillMount() {
    //this.getAllData()
    
  }

  componentDidMount() {
    this.getAllData();
    this.ticker = setInterval(() => this.getAllData(), 5000);
  }

  componentWillUnmount() {
    clearInterval(this.ticker);
  }

  clickHandlerFetch(){
    this.getAllData();
  }

  clickHandler(){
    this.setState({
      data: [],
      isLoaded: false
    })

    //console.log("state: ", this.state.data)
  }

  render() {
    const { classes } = this.props;
    const {data, isLoaded, settings} = this.state

    if(!isLoaded) {
      return (<div><br /><CircularProgress /><br />loading...</div>)
    } else {
      return (
        <div className={classes.root}>
          <Container maxWidth="lg">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} style={{margin: 'auto 0'}}>
                <HeaderStatus token={this.state.token} />
                {/* <Chip color="primary" className={classes.headerLabel} label="ONLINE BESTELLUNG AKTIV - " style={{ backgroundColor: green[500] }} />&nbsp; */}
                {/* <Chip color="primary" className={classes.headerLabel} label="+ 15min" style={{ backgroundColor: grey[500] }} /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <Clock /> */}
                {/* <ClockAsFunction /> */}
              </Grid>
              <Grid item xs={12}>
                {/* <OrderTable data={data} /> */}
                {/* <OrderTableAdvanced data={data} /> */}
              </Grid>
              <Grid item xs={12} m={6}>
                <Box mt={4}>
                  {/*<SettingsForm />*/}
                </Box>
              </Grid>
            </Grid>
            <Button onClick={this.clickHandler} variant="contained" color="primary">Click me</Button>
            <Button onClick={this.clickHandlerFetch} variant="contained" color="secondary">Update fetch</Button>
          </Container>
        </div>
      );
    }
  }
}

export default withStyles(styles)(OrderView);
//export default OrderView
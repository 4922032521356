import React, {useState, useEffect} from 'react';
import { Button, Grid, IconButton, Icon } from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Auth from '../utils/Auth'

function NachrichtenEdit(props) {
  const [open, setOpen] = useState(false);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [nachricht, setNachricht] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();

    const data = {
      title: title,
      content: nachricht,
    }

    Auth.axios.put(`wp-json/wp/v2/kiapopup/${id}`, data).then(
      res => {
        console.log("res", res);
        setOpen(false);
      }
    ).catch(
      err => {
        console.log("err", err);
      }
    )

  };

  useEffect(() => {
    if(props.data) {
      console.log(`props.data`, props.data);
      setTitle(props.data.title);
      setNachricht(props.data.content);
      setId(props.data.id);
    }

    return () => {
      // cleanup
    }
  }, []);


  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Editieren
      </Button> */}
      <IconButton onClick={handleClickOpen} ><Icon style={{ color: grey }}>edit</Icon></IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nachricht bearbeiten</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tragen Sie einen neuen Titel oder eine neue Nachricht ein.
          </DialogContentText>
          <TextField autoFocus margin="dense" id="title" label="Titel" type="text" fullWidth variant="filled" defaultValue={title} onChange={(e) => {setTitle(e.target.value)}}/>
          <TextField id="filled-multiline-static" label="Nachricht" multiline rows={5} defaultValue={nachricht} variant="filled" fullWidth onChange={(e) => {setNachricht(e.target.value)}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">Speichern</Button>
          <Button onClick={handleClose} color="primary">Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NachrichtenEdit;
import React, {useState, useEffect} from 'react'
import { Chip, Container, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green, grey, red } from '@material-ui/core/colors';
import ClockAsFunction from './ClockAsFunction';
import Auth from '../utils/Auth';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  grow: {
    flexGrow: 1,
  },
  icoPrinted: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[500],
    },
  },
  headerLabel: {
    fontWeight: 'bold',
    fontSize: '16px',
  }
}));

// Verwenden

function BestellungenTopBar() {
  const classes = useStyles();
  const [onlineStatus, setOnlineStatus] = useState(null);
  const [onlineStatusColor, setOnlineStatusColor] = useState('green');
  const [plustime, setPlustime] = useState(null);

  useEffect(() => {
    
    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_online').then(
      res => {
        console.log("res: ", res);
        
        if(res.data == 'on') {
          setOnlineStatus('ONLINE BESTELLUNG AKTIV');
          setOnlineStatusColor("#4CAF50");
        } else {
          setOnlineStatus('BESTELLUNG OFFLINE');
          setOnlineStatusColor("#F44236");
        }
      }
    ).catch(
      err => {
        console.log(`err`, err);
      }
    )

    Auth.axios.get('wp-json/kiarest/v1/settings/kia_order_plustime').then(
      res => {
        console.log("res: ", res);
        setPlustime(res.data);
      }
    ).catch(
      err => {
        console.log(`err`, err);
      }
    )

    return () => {
      //cleanup
    }
  }, [])

  return (
    <React.Fragment>
      {/* {onlineStatus && <Chip color="primary" className={classes.headerLabel} label={onlineStatus} style={{ backgroundColor: green[500] }} />}&nbsp; */}
      {/* <div className={classes.grow} /> */}
      {onlineStatus && <Chip color="primary" className={classes.headerLabel} label={onlineStatus} style={{ backgroundColor: `${onlineStatusColor}` }} />}&nbsp;
      {plustime && <Chip color="primary" className={classes.headerLabel} label={`+ ${plustime}min`} style={{ backgroundColor: grey[500] }} />}&nbsp;&nbsp;
      <ClockAsFunction />
    </React.Fragment>
  )
}

export default BestellungenTopBar

import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Auth from '../utils/Auth'

function NachrichtenNew(props) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [nachricht, setNachricht] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();

    const data = {
      title: title,
      content: nachricht,
      status: 'draft'
    }

    Auth.axios.post('wp-json/wp/v2/kiapopup', data).then(
      res => {
        console.log("res", res);
        setOpen(false);
        props.setUpdate(new Date());
      }
    ).catch(
      err => {
        console.log("err", err);
      }
    )

  };

  useEffect(() => {
    
    return () => {
      // cleanup
    }
  }, []);


  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Neue Erstellen
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Neue Nachricht</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Eine neue Nachricht erstellen, die auf der Homepage angezeigt wird.
          </DialogContentText>
          <TextField autoFocus margin="dense" id="title" label="Titel" type="text" fullWidth variant="filled" onChange={(e) => {setTitle(e.target.value)}}/>
          <TextField id="filled-multiline-static" label="Nachricht" multiline rows={5} defaultValue="" variant="filled" fullWidth onChange={(e) => {setNachricht(e.target.value)}} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">Speichern</Button>
          <Button onClick={handleClose} color="primary">Abbrechen</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default NachrichtenNew;
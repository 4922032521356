import axios from 'axios';
import config from './config.js';

export const Auth = {
  loggedin:false,
  login,
  logout,
  requestCurrentUser,
  get currentUser() { return JSON.parse(localStorage.getItem('currentUser')); },
  get isLoggedIn() { return this.loggedin; },
  get axios() { return axiosInstance; }
}

function login(username, password) {
  
  return axiosInstance.post('wp-json/jwt-auth/v1/token', {username, password}).then(
    res => {
      console.log("Auth.js /token result: ", res.data.token);
      localStorage.setItem('token', res.data.token);
      this.loggedin = true;

      return res;
    }
  ).catch(
    err => {
      console.log("Login.js /token catch: ", err);
      //setMessage("Falsches Passwort.");
      //setLoggedIn(false);
      this.isloggedin = false;
      localStorage.clear();
      //props.setUser(null);
      return err;
    }
  )
}

function requestCurrentUser() {
    return axiosInstance.post('wp-json/wp/v2/users/me').then(
      res => {
        console.log("Auth.js res: ", res);
        console.log("Auth.js res.data: ", res.data);
        //props.setUser(res.data);
        localStorage.setItem('currentUser', JSON.stringify(res.data));

        return res;
      }
    ).catch(
      err => {
        console.log("/users/me",err);
        return err;
      }
    )
}

function logout(){
  this.isloggedin = false;
  localStorage.clear();
  // localStorage.removeItem("currentUser");
  // localStorage.removeItem("token");
}

const axiosInstance = axios.create({
  // baseURL: 'https://sushipark.de',
  baseURL: config.siteurl,
});

axiosInstance.interceptors.request.use(
  config => {
    if(localStorage.getItem('token') != null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

axiosInstance.interceptors.response.use(
  config => {
    if(localStorage.getItem('token') != null) {
      config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

export default Auth;
